import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import Moment from "react-moment";
import {Link} from "react-router-dom";

const ShowOrderConfirmation = ({handleOrderPlace, handleOrderSummaryInput, ...props}) => {

    const [branch, setBranch] = useState({})

    useEffect(() => {
        if(localStorage.branch !== undefined){
            setBranch(JSON.parse(localStorage.branch))
        }
    }, [])

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body>
                <div className="container-fluid invoice-container">

                    <header>
                        <div className="row align-items-center">
                            <div className="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
                                <img id="logo" className="img-thumbnail w-25" src={branch.logo !== undefined ? branch.logo : null} title="Koice" alt="Koice"/>
                            </div>
                            <div className="col-sm-5 text-center text-sm-end">
                                <h4 className="text-7 mb-0">Invoice</h4>
                            </div>
                        </div>
                        <hr/>
                    </header>

                    <main>
                        <div className="row">
                            <div className="col-sm-6"><strong>Date:</strong> <Moment format="DD MMMM, YYYY"></Moment></div>
                            <div className="col-sm-6 text-sm-end"><strong>Invoice No:</strong> 16835</div>

                        </div>
                        <hr/>
                            <div className="row">
                                <div className="col-sm-6 text-sm-end order-sm-1"><strong>Pay To:</strong>
                                    {Object.keys(branch).length > 0 ?
                                    <>
                                        <address>
                                            {branch.name}<br/>
                                            {branch.address.address}<br/>
                                            {branch.address.division}<br/>
                                            {branch.phone}
                                        </address>
                                    </> : null
                                    }

                                </div>
                                <div className="col-sm-6 order-sm-0"><strong>Invoiced To:</strong>
                                    <address>
                                        {props.order_summary.customer ? props.order_summary.customer.split('-')[0] : null}<br/>
                                        {props.order_summary.customer ? props.order_summary.customer.split('-')[1] : null}
                                    </address>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-bordered">
                                            <thead className="card-header">
                                            <tr>
                                                <td><strong>No</strong></td>
                                                <td><strong>Description</strong></td>
                                                <td className="text-center"><strong>Rate</strong></td>
                                                <td className="text-center"><strong>QTY</strong></td>
                                                <td className="col-4 text-end"><strong>Amount</strong></td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {Object.keys(props.carts).map((key,index)=>(
                                                <tr>
                                                    <td>{++index}</td>
                                                    <td className="text-1">{props.carts[key].name}</td>
                                                    <td className="text-center">{props.carts[key].price}</td>
                                                    <td className="text-center">{props.carts[key].quantity}</td>
                                                    <td className="text-end">
                                                        {new Intl.NumberFormat('us').format(props.carts[key].original_price * props.carts[key].quantity)}৳
                                                    </td>
                                                </tr>
                                            ))}

                                            </tbody>
                                            <tfoot className="card-footer">
                                            <tr>
                                                <td colSpan="4" className="text-end"><strong>Sub Total:</strong></td>
                                                <td className="text-end">
                                                    {new Intl.NumberFormat('us').format(props.order_summary.amount)}৳
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" className="text-end"><strong>Discount:</strong></td>
                                                <td className="text-end">-
                                                    {new Intl.NumberFormat('us').format(props.order_summary.discount)}৳
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" className="text-end border-bottom">
                                                    <strong>Total:</strong></td>
                                                <td className="text-end border-bottom">
                                                    {new Intl.NumberFormat('us').format(props.order_summary.pay_able)}৳
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" className="text-end border-bottom">
                                                    <strong>Paid Amount:</strong>
                                                </td>
                                                <td className="text-end border-bottom">
                                                    <div className="input-group">
                                                        <input
                                                            className='form-control form-control-sm text-end'
                                                            type={'number'}
                                                            name={'paid_amount'}
                                                            value={props.order_summary.paid_amount}
                                                            onChange={handleOrderSummaryInput}
                                                        />
                                                        <div className="input-group-text">৳</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" className="text-end border-bottom">
                                                    <strong>Due Amount:</strong></td>
                                                <td className="text-end border-bottom">
                                                    {new Intl.NumberFormat('us').format(props.order_summary.due_amount)}৳
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" className="text-end border-bottom">
                                                    <strong>Select Payment Methods:</strong></td>
                                                <td className="border-bottom text-end">
                                                    <select
                                                        className="form-select form-select-sm text-end"
                                                        name="payment_method_id"
                                                        value={props.order_summary.payment_method_id}
                                                        onChange={handleOrderSummaryInput}
                                                        >
                                                        {props.paymentMethods.map((paymentMethod, index)=>(
                                                            <option value={paymentMethod.id} key={index}>{paymentMethod.name}</option>
                                                        ))}


                                                    </select>
                                                </td>
                                            </tr>
                                            {props.order_summary.payment_method_id != 1?
                                                <tr>
                                                    <td colSpan="4" className="text-end border-bottom">Transaction ID:</td>
                                                    <td>
                                                        <input
                                                            className='form-control form-control-sm text-end'
                                                            type={'text'}
                                                            name={'transaction_id'}
                                                            value={props.order_summary.transaction_id}
                                                            onChange={handleOrderSummaryInput}
                                                            placeholder={'Your transaction id'}
                                                        />
                                                    </td>
                                                </tr>: null
                                            }

                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                    </main>
                    <footer className="text-center mt-4">
                        <p className="text-1"><strong>NOTE :</strong> This is computer generated receipt and does not
                            require physical signature.</p>
                        <div className="btn-group btn-group-sm d-print-none">
                            <Link to={"javascript:window.print()"} className="btn btn-light border text-black-50 shadow-none">
                                <i className="fa fa-print"></i> Print</Link>
                            <Link to="" className="btn btn-light border text-black-50 shadow-none">
                                <i className="fa fa-download"></i> Download</Link>
                        </div>
                    </footer>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='px-4'>
                    <button className="btn btn-sm btn-danger" onClick={props.onHide}>Close</button>
                    <button onClick={handleOrderPlace}
                            className='btn btn-theme btn-sm ms-4'
                            dangerouslySetInnerHTML={{__html: props.is_loading ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>\n' +
                            '  Loading...' : 'Confirm'}}/>
                </div>
            </Modal.Footer>

        </Modal>
    );
};

export default ShowOrderConfirmation;