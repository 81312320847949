import React from 'react';
import {Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";

const CardButton = (props) => {
    return (
        <>
            <Card.Header as={props.heading ? props.heading : 'h6'}>{props.title}
                {props.card_btn !== undefined ?
               <Link to={props.link}>
                   <Button onClick={props.handle_function ? props.handle_function : null}
                       variant={props.btn_color ? props.btn_color : 'primary'}
                       size={props.btn_size ? props.btn_size : 'lg'}
                       className="float-end">
                       <i className={`fa-solid ${props.icon}`}></i>
                        {props.button_text ? props.button_text : null}
                   </Button>
               </Link> : null
            }
            </Card.Header>
        </>
    );
};

export default CardButton;