import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "../../partials/BreadCrumb";
import CardHeader from "../../partials/mini_component/CardHeader";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Constants from "../../../Constants";
import Swal from "sweetalert2";

const ShopEdit = () => {

    const navigate = useNavigate()
    const params = useParams();
    const [input, setInput] = useState({status: 1})
    const [divisions, setDivisions] = useState([])
    const [districts, setDistricts] = useState([])
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [areas, setAreas] = useState([])

    const getShops = () => {
        axios.get(`${Constants.BASE_URL}/shop/${params.id}`).then(res => {
            setInput(res.data.data)
            getDistricts(res.data.data.division_id)
            getAreas(res.data.data.district_id)
        })
    }
    const getDivisions = () => {
        axios.post(`${Constants.BASE_URL}/divisions`).then(res=>{
            setDivisions(res.data)
        })
    }

    const getDistricts = (division_id) => {
        axios.post(`${Constants.BASE_URL}/districts/${division_id}`).then(res=>{
            setDistricts(res.data)
        })
    }

    const getAreas = (district_id) => {
        axios.post(`${Constants.BASE_URL}/areas/${district_id}`).then(res=>{
            setAreas(res.data)
        })
    }
    const handleInput = (e) =>{
        if (e.target.name === 'division_id'){
            setDistricts([])
            setAreas([])
            let division_id = parseInt(e.target.value)
            if (!isNaN(division_id)) getDistricts(division_id)
        }else if(e.target.name === 'district_id'){
            setAreas([])
            let district_id = parseInt(e.target.value)
            if (!isNaN(district_id)) getAreas(district_id)
        }
        setInput(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
    }


    const handleLogo = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()
        reader.onloadend = () => {
            setInput(prevState => ({...prevState, logo: reader.result}))
        }
        reader.readAsDataURL(file)

    }
    const handleShopEdit = () => {
        setIsLoading(true)
        axios.put(`${Constants.BASE_URL}/shop/${params.id}`, input).then(res=>{
            setIsLoading(false)
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast:true,
                timer: 1500
            })
            if (res.data.flag === undefined){
                navigate('/shop')
            }
        }).catch(errors => {
            setIsLoading(false)
            if (errors.response.status === 422){
                setErrors(errors.response.data.errors)
            }
        })
    }

    useEffect (() =>{
        getDivisions()
        getShops()
    },[])

    return (
        <>
            <BreadCrumb title={'Edit Shop'} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <CardHeader
                                title={'Edit Shop'}
                                link={'/shop'}
                                icon={'fa-list'}
                                button_text={'List'}
                            />
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6>Shop Details</h6>
                                        </div>
                                        <div className="card-body">
                                            <label className={'w-100'}>
                                                <p>Company Name:</p>
                                                <input
                                                    className={errors.name !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                    name={'name'}
                                                    type={'text'}
                                                    value={input.name}
                                                    onChange={handleInput}
                                                    placeholder={'Enter company name'}
                                                />
                                                <p className={'login-error-msg'}><small>{errors.name !== undefined ? errors.name[0] : null}</small></p>
                                            </label>
                                            <label className={'w-100 mt-4'}>
                                                <p>Phone:</p>
                                                <input
                                                    className={errors.phone !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                    name={'phone'}
                                                    type={'text'}
                                                    value={input.phone}
                                                    onChange={handleInput}
                                                    placeholder={'Enter shop phone'}
                                                />
                                                <p className={'login-error-msg'}><small>{errors.phone !== undefined ? errors.phone[0] : null}</small></p>
                                            </label>
                                            <label className={'w-100 mt-4'}>
                                                <p>Email Address:</p>
                                                <input
                                                    className={errors.email !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                    name={'email'}
                                                    type={'email'}
                                                    value={input.email}
                                                    onChange={handleInput}
                                                    placeholder={'Enter shop email'}
                                                />
                                                <p className={'login-error-msg'}><small>{errors.email !== undefined ? errors.email[0] : null}</small></p>
                                            </label>
                                            <label className={'w-100 mt-4'}>
                                                <p>Status:</p>
                                                <select
                                                    className={errors.status !== undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
                                                    name={'status'}
                                                    value={input.status}
                                                    onChange={handleInput}
                                                >
                                                    <option value={1}>Active</option>
                                                    <option value={0}>In Active</option>
                                                </select>
                                                <p className={'login-error-msg'}><small>{errors.status !== undefined ? errors.status[0] : null}</small></p>
                                            </label>
                                            <label className={'w-100 mt-4'}>
                                                <p>Details:</p>
                                                <textarea
                                                    className={errors.details !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                    name={'details'}
                                                    value={input.details}
                                                    onChange={handleInput}
                                                    placeholder={'Enter shop details'}
                                                />
                                                <p className={'login-error-msg'}><small>{errors.details !== undefined ? errors.details[0] : null}</small></p>
                                            </label>
                                            <label className={'w-100 mt-4'}>
                                                <p>Logo:</p>
                                                <input
                                                    className={errors.logo !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                    name={'logo'}
                                                    type={'file'}
                                                    onChange={handleLogo}
                                                />
                                                <p className={'login-error-msg'}><small>{errors.logo !== undefined ? errors.logo[0] : null}</small></p>
                                            </label>
                                            {input.logo !== undefined || input.display_logo !== undefined?
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className={'photo-preview mt-3'}>
                                                            <img alt={'logo title'} src={input.logo === undefined ? input.display_logo : input.logo} className={'img-thumbnail aspect-one'}/>
                                                        </div>
                                                    </div>
                                                </div> :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="card">
                                        <div className="card-header">
                                            <h6>Shop Address</h6>
                                        </div>
                                        <div className="card-body">
                                            <label className={'w-100'}>
                                                <p>Address <small>(House/Road/Village etc)</small></p>
                                                <textarea
                                                    className={errors.address !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                    name={'address'}
                                                    value={input.address}
                                                    onChange={handleInput}
                                                    placeholder={'Enter shop address'}
                                                />
                                                <p className={'login-error-msg'}><small>{errors.address !== undefined ? errors.address[0] : null}</small></p>
                                            </label>
                                            <label className={'w-100 mt-4'}>
                                                <p>Select Division:</p>
                                                <select
                                                    className={errors.division_id !== undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
                                                    name={'division_id'}
                                                    value={input.division_id}
                                                    onChange={handleInput}
                                                >
                                                    <option>Select Division</option>
                                                    {divisions.map((division, index)=>(
                                                        <option key={index} value={division.id}>{division.name}</option>
                                                    ))}
                                                </select>
                                                <p className={'login-error-msg'}><small>{errors.division_id !== undefined ? errors.division_id[0] : null}</small></p>
                                            </label>
                                            <label className={'w-100 mt-4'}>
                                                <p>Select District:</p>
                                                <select
                                                    className={errors.district_id !== undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
                                                    name={'district_id'}
                                                    value={input.district_id}
                                                    onChange={handleInput}
                                                    disabled={Object.keys(districts).length < 1}
                                                >
                                                    <option>Select District</option>
                                                    {districts.map((district, index)=>(
                                                        <option key={index} value={district.id}>{district.name}</option>
                                                    ))}
                                                </select>
                                                <p className={'login-error-msg'}><small>{errors.district_id !== undefined ? errors.district_id[0] : null}</small></p>
                                            </label>
                                            <label className={'w-100 mt-4'}>
                                                <p>Select Area:</p>
                                                <select
                                                    className={errors.area_id !== undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
                                                    name={'area_id'}
                                                    value={input.area_id}
                                                    onChange={handleInput}
                                                    disabled={Object.keys(areas).length < 1}
                                                >
                                                    <option>Select Area</option>
                                                    {areas.map((area, index)=>(
                                                        <option key={index} value={area.id}>{area.name}</option>
                                                    ))}
                                                </select>
                                                <p className={'login-error-msg'}><small>{errors.area_id !== undefined ? errors.area_id[0] : null}</small></p>
                                            </label>
                                            <label className={'w-100 mt-4'}>
                                                <p>Landmark (Optional)</p>
                                                <input
                                                    className={errors.name !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                    name={'landmark'}
                                                    type={'text'}
                                                    value={input.landmark}
                                                    onChange={handleInput}
                                                    placeholder={'Enter landmark'}
                                                />
                                                <p className={'login-error-msg'}><small>{errors.landmark !== undefined ? errors.landmark[0] : null}</small></p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">


                                <div className={'col-md-12'}>
                                    <div className={'row justify-content-center'}>
                                        <div className={'col-md-4'}>
                                            <div className={'d-grid mt-4'}>
                                                <button onClick={handleShopEdit} className='btn btn-theme' dangerouslySetInnerHTML={{__html: isLoading ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>\n' +
                                                        '  Loading...' : 'Edit Shop'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShopEdit;