import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "../../partials/BreadCrumb";
import Loader from "../../partials/mini_component/Loader";
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import axios from "axios";
import Constants from "../../../Constants";
import GlobalFunction from "../../../GlobalFunction";
import CardButton from "../../partials/mini_component/CardButton";
import {Card, Table} from "react-bootstrap";

const OrderList = () => {

    const [input,setInput] = useState({
        order_by : 'serial',
        per_page : 10,
        direction : 'asc',
        search : ''

    })

    const [isLoading, setIsLoading] = useState(false)
    const [order, setOrder] = useState([])

    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState([])
    const [startFrom, setStartFrom] = useState(1)
    const [activePage, setActivePage] = useState(1)

    const [orders, setOrders] = useState([])

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const getOrders = (pageNumber=1) => {
        setIsLoading(true)
        axios.get(`${Constants.BASE_URL}/order?page=${pageNumber}&search=${input.search}&order_by=${input.order_by}&per_page=${input.per_page}&direction=${input.direction}`).then(res =>{
            setOrders(res.data.data)
            setItemsCountPerPage(res.data.meta.per_page)
            setStartFrom(res.data.meta.from)
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getOrders()
    }, [])

    return (
        <>
            <BreadCrumb title={'Order List'}/>
            <Card>
                <CardButton
                    card_btn
                    title={'Order List'}
                    heading={'h5'}
                    link={'/order/create'}
                    icon={'fa-add'}
                    btn_color={'theme'}
                    button_text={' Order Create'}
                    btn_size={'sm'}
                />
                <Card.Body>
                    <div className="search-area mb-4">
                        <div className="row">
                            <div className="col-md-3">
                                <label className="w-100">
                                    <p>Search</p>
                                    <input
                                        className="form-control form-control-sm"
                                        name={'search'}
                                        type={'search'}
                                        value={input.search}
                                        onChange={handleInput}
                                        placeholder={'Search..'}
                                    />
                                </label>
                            </div>
                            <div className="col-md-3">
                                <label className="w-100">
                                    <p>Order By</p>
                                    <select
                                        className="form-select form-select-sm"
                                        name={'order_by'}
                                        value={input.order_by}
                                        onChange={handleInput}
                                    >
                                        <option value={'name'}>Name</option>
                                        <option value={'created_at'}>Created at</option>
                                        <option value={'updated_at'}>Updated at</option>
                                        <option value={'status'}>Status</option>
                                    </select>
                                </label>
                            </div>
                            <div className="col-md-2">
                                <label className="w-100">
                                    <p>Order Direction</p>
                                    <select
                                        className="form-select form-select-sm"
                                        name={'direction'}
                                        value={input.direction}
                                        onChange={handleInput}
                                    >
                                        <option value={'asc'}>ASC</option>
                                        <option value={'desc'}>DSC</option>
                                    </select>
                                </label>
                            </div>
                            <div className="col-md-2">
                                <label className="w-100">
                                    <p>Per Page</p>
                                    <select
                                        className="form-select form-select-sm"
                                        name={'per_page'}
                                        value={input.per_page}
                                        onChange={handleInput}
                                    >
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                </label>
                            </div>
                            <div className={'col-md-2'}>
                                <div className={'d-grid mt-4'}>
                                    <button onClick={()=>getOrders(1)} className='btn btn-sm btn-theme'><i className="fa-solid fa-magnifying-glass"/> Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading ? <Loader/> :
                        <Table striped bordered hover size="sm" className={'align-items-center'}>
                            <thead>
                            <tr>
                                <th>No.</th>
                                <th>Order Details</th>
                                <th>Customer</th>
                                <th>Amount</th>
                                <th>Sales</th>
                                <th>Date & Time</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map((order,index)=>(
                                <tr key={index}>
                                    <td>{startFrom + index}</td>
                                    <td>
                                        <p>Order No. <strong>{order.order_number}</strong></p>
                                        <p className="text-theme">Order Status: {order.order_status_string}</p>
                                        <p>Payment Status: {order.payment_status}</p>
                                    </td>
                                    <td>
                                        <p>{order.customer_name}</p>
                                        <p className={'text-theme'}>{order.customer_phone}</p>
                                    </td>
                                    <td>
                                        <p>Quantity: {order.quantity}</p>
                                        <p>Sub Total: {GlobalFunction.formatPrice(order.sub_total)}</p>
                                        <p className={'text-theme'}>Discount: {GlobalFunction.formatPrice(order.discount)}</p>
                                        <p>Total: {GlobalFunction.formatPrice(order.total)}</p>
                                        <p className={'text-theme'}>Due Amount: {GlobalFunction.formatPrice(order.due_amount)}</p>
                                        <p>Paid Amount: {GlobalFunction.formatPrice(order.paid_amount)}</p>
                                    </td>
                                    <td>
                                        <p>Shop: {order.shop}</p>
                                        <p className={'text-theme'}>Sales Mag. : {order.sales_manager}</p>
                                    </td>
                                    <td>
                                        <p>{order.created_at}</p>
                                        <p className={'text-theme'}>{order.updated_at}</p>
                                    </td>
                                    <td>
                                        <Link to={`/order/${order.id}`}>
                                            <button className={'btn btn-sm btn-theme'}><i className="fa-solid fa-eye"/></button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    }
                </Card.Body>
                <Card.Footer>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={getOrders}
                        nextPageText={'Next'}
                        prevPageText={'Previous'}
                        itemClass={'page-item'}
                        linkClass={'page-link'}
                    />
                </Card.Footer>
            </Card>
        </>
    );
};

export default OrderList;