import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "../../partials/BreadCrumb";
import CardHeader from "../../partials/mini_component/CardHeader";
import Loader from "../../partials/mini_component/Loader";
import {Link} from "react-router-dom";
import NoDataFound from "../../partials/mini_component/NoDataFound";
import ImageModal from "../../partials/modals/ImageModal";
import CategoryDetailModal from "../../partials/modals/CategoryDetailModal";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import axios from "axios";
import Constants from "../../../Constants";

const BrandList = () => {
    const [input,setInput] = useState({
        order_by : 'serial',
        per_page : 10,
        direction : 'asc',
        search : ''

    })

    const [isLoading, setIsLoading] = useState(false)
    const [brand, setBrand] = useState([])

    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState([])
    const [startFrom, setStartFrom] = useState(1)
    const [activePage, setActivePage] = useState(1)

    const [modalShow, setModalShow] = React.useState(false)
    const [modalImageShow, setModalImageShow] = React.useState(false)
    const [categories, setCategories] = useState([])
    const [modalPhoto, setModalPhoto] = useState('')

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const handleBrandDelete = (id) =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "Brand will be deleted!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constants.BASE_URL}/brand/${id}`).then(res=>{
                    getCategories()
                    Swal.fire({
                        position: 'top-end',
                        icon: res.data.cls,
                        title: res.data.msg,
                        showConfirmButton: false,
                        toast: true,
                        timer: 1500
                    })
                })
            }
        })

    }
    const handleImageModal = (photo) => {
        setModalPhoto(photo)
        setModalImageShow(true)
    }
    const handleDetailsModal = (brand) => {
        setBrand(brand)
        setModalShow(true)
    }
    const getCategories = (pageNumber=1) => {
        setIsLoading(true)
        axios.get(`${Constants.BASE_URL}/brand?page=${pageNumber}&search=${input.search}&order_by=${input.order_by}&per_page=${input.per_page}&direction=${input.direction}`).then(res =>{
            setCategories(res.data.data)
            setItemsCountPerPage(res.data.meta.per_page)
            setStartFrom(res.data.meta.from)
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getCategories()
    }, [])
    return (
        <>
            <BreadCrumb title={'All Brand'}/>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4">
                        <div className="card-header">
                            <CardHeader
                                title={'Brand List'}
                                link={'/brand/create'}
                                icon={'fa-add'}
                                button_text={'Add'}
                            />
                        </div>
                        <div className={'card-body'}>
                            <div className="search-area mb-4">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="w-100">
                                            <p>Search</p>
                                            <input
                                                className="form-control form-control-sm"
                                                name={'search'}
                                                type={'search'}
                                                value={input.search}
                                                onChange={handleInput}
                                                placeholder={'Search..'}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="w-100">
                                            <p>Order By</p>
                                            <select
                                                className="form-select form-select-sm"
                                                name={'order_by'}
                                                value={input.order_by}
                                                onChange={handleInput}
                                            >
                                                <option value={'name'}>Name</option>
                                                <option value={'created_at'}>Created at</option>
                                                <option value={'updated_at'}>Updated at</option>
                                                <option value={'status'}>Status</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="w-100">
                                            <p>Order Direction</p>
                                            <select
                                                className="form-select form-select-sm"
                                                name={'direction'}
                                                value={input.direction}
                                                onChange={handleInput}
                                            >
                                                <option value={'asc'}>ASC</option>
                                                <option value={'desc'}>DSC</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="w-100">
                                            <p>Per Page</p>
                                            <select
                                                className="form-select form-select-sm"
                                                name={'per_page'}
                                                value={input.per_page}
                                                onChange={handleInput}
                                            >
                                                <option value={10}>10</option>
                                                <option value={25}>25</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className={'col-md-2'}>
                                        <div className={'d-grid mt-4'}>
                                            <button onClick={()=>getCategories(1)} className='btn btn-sm btn-theme'><i className="fa-solid fa-magnifying-glass"/> Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isLoading ? <Loader/> :
                                <div className="table-responsive">
                                    <table className={'my-table table table-hover table-striped table-bordered'}>
                                        <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Name / Slug</th>
                                            <th>Serial / Status</th>
                                            <th>Photo</th>
                                            <th>Created By</th>
                                            <th>Date Time</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.keys(categories).length > 0 ? categories.map((brand, index)=> (
                                            <tr key={index}>
                                                <td>{startFrom + index}</td>
                                                <td>
                                                    <p>{brand.name}</p>
                                                    <p>{brand.slug}</p>
                                                </td>
                                                <td>
                                                    <p>Serial: {brand.serial}</p>
                                                    <p>Status: {brand.status}</p>
                                                </td>
                                                <td><img onClick={ () => handleImageModal(brand.photo_full)} src={brand.photo} alt={brand.name} className={'img-thumbnail table-image'}/></td>
                                                <td>{brand.created_by}</td>
                                                <td>
                                                    <p>{brand.created_at}</p>
                                                    <p>{brand.updated_at}</p>
                                                </td>
                                                <td>
                                                    <button onClick={ () => handleDetailsModal(brand)} className={'btn btn-sm btn-theme my-1'}><i className="fa-solid fa-eye"/></button>
                                                    <Link to={`/brand/edit/${brand.id}`}><button className={'btn btn-sm btn-primary my-1 mx-1'}><i className="fa-solid fa-edit"/></button></Link>
                                                    <button onClick={()=>handleBrandDelete(brand.id)} className={'btn btn-sm btn-danger my-1'}><i className="fa-solid fa-trash"/></button>
                                                </td>
                                            </tr>
                                        )): <NoDataFound/> }

                                        </tbody>
                                    </table>
                                    <ImageModal
                                        show={modalImageShow}
                                        onHide={() => setModalImageShow(false)}
                                        title={'Brand Logo'}
                                        size={''}
                                        photo={modalPhoto}
                                    />
                                    <CategoryDetailModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        title={'Brand Details'}
                                        size={''}
                                        category={brand}
                                    />
                                </div>
                            }

                        </div>
                        <div className="card-footer">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={5}
                                onChange={getCategories}
                                nextPageText={'Next'}
                                prevPageText={'Previous'}
                                itemClass={'page-item'}
                                linkClass={'page-link'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BrandList;