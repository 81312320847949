import React, {useEffect, useRef, useState} from 'react';
import {BreadCrumb} from "../../partials/BreadCrumb";
import CardButton from "../../partials/mini_component/CardButton";
import {Card} from "react-bootstrap";
import axios from "axios";
import Constants from "../../../Constants";
import GlobalFunction from "../../../GlobalFunction";
import Barcode from "react-barcode";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import BarCodePage from "./BarCodePage";

const BarCode = () => {
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })
    const [input, setInput] = useState({
        name:'',
        category_id: '',
        sub_category_id: ''
    })
    const [isLoading, setIsLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [products, setProducts] = useState([])
    const [paperSize, setPaperSize] = useState({
        a4:{
            width: 595,
            height: 842
        }
    })

    const handleInput = (e) =>{
        if(e.target.name === 'category_id'){
            setSubCategories([])
            let category_id = parseInt(e.target.value)
            if (!Number.isNaN(category_id)) getSubCategories(category_id)
        }
        setInput(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
    }
    const getCategories = () => {
        axios.get(`${Constants.BASE_URL}/get-category-list`).then(res=>{
            setCategories(res.data)
        })
    }

    const getSubCategories = (category_id) => {
        axios.get(`${Constants.BASE_URL}/get-sub-category-list/${category_id}`).then(res=>{
            setSubCategories(res.data)
        })
    }
    const handleProductSearch = () => {
        axios.get(`${Constants.BASE_URL}/get-product-list-for-bar-code?name=${input.name}&category_id=${input.category_id}&sub_category_id=${input.sub_category_id}`).then(res=>{
            setProducts(res.data.data)
        })
    }

    useEffect(()=>{
        getCategories()
    },[])
    return (
        <>
            <BreadCrumb title={'Generate and Print Bar Code'}/>
            <Card>
                <CardButton
                    card_btn
                    title={'Generate and Print Bar Code'}
                    heading={'h5'}
                    link={'#'}
                    icon={'fa-list'}
                    btn_color={'theme'}
                    button_text={' List'}
                    btn_size={'sm'}
                />
                <Card.Body>
                    <div className='row align-items-baseline'>
                        <div className="col-md-3">
                            <label className='w-100 mt-4 mt-md-0'>
                                <p>Select Category</p>
                                <select
                                    className={'form-control mt-2'}
                                    name={'category_id'}
                                    value={input.category_id}
                                    onChange={handleInput}
                                >
                                    <option value={''}>Select Category</option>
                                    {categories.map((category, index)=>(
                                        <option key={index} value={category.id}>{category.name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div className="col-md-3">
                            <label className='w-100 mt-4 mt-md-0'>
                                <p>Select Sub Category</p>
                                <select
                                    className={'form-control mt-2'}
                                    name={'sub_category_id'}
                                    value={input.sub_category_id}
                                    onChange={handleInput}
                                    disabled={Object.keys(subCategories).length < 1}
                                >
                                    <option value={''}>Select Sub Category</option>
                                    {subCategories.map((sub_category, index)=>(
                                        <option key={index} value={sub_category.id}>{sub_category.name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div className='col-md-4'>
                            <label className='w-100 mt-4 mt-md-0'>
                                <p>Name</p>
                                <input
                                    className='form-control mt-2'
                                    type={'search'}
                                    name={'name'}
                                    value={input.name}
                                    onChange={handleInput}
                                    placeholder={'Enter product name'}

                                />
                            </label>
                        </div>
                        <div className="col-md-2">
                            <div className={'d-grid mt-4'}>
                                <button onClick={handleProductSearch} className='btn btn-theme' dangerouslySetInnerHTML={{__html: isLoading ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>\n' +
                                        '  Loading...' : 'Search'}}/>
                            </div>
                        </div>
                    </div>
                    <div className='print-area-parent' style={{display: Object.keys(products).length > 0 ? 'block' : 'none'}}>
                        <button className='btn btn-theme mt-2 float-end' onClick={handlePrint}>Print</button>
                        <div className='bar-code-wrapper'>
                            <BarCodePage
                                products = {products}
                                paperSize= {paperSize}
                                ref= {componentRef}
                            />
                        </div>
                    </div>


                </Card.Body>
            </Card>
        </>
    );
};

export default BarCode;