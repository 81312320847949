import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import axios from "axios";
import Constants from "../../../../Constants";
import Swal from "sweetalert2";
import CardButton from "../../../partials/mini_component/CardButton";

const AddCustomer = ({setModalShow, ...props}) => {

    const [input, setInput] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const handleInput = (e) =>{
       setInput(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
    }

    const handleCustomerCreate = () => {
        setIsLoading(true)
        axios.post(`${Constants.BASE_URL}/customer`, input).then(res=>{
            setIsLoading(false)
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast:true,
                timer: 1500
            })
            setModalShow(false)
        }).catch(errors => {
            setIsLoading(false)
            if (errors.response.status === 422){
                setErrors(errors.response.data.errors)
            }
        })
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Customer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label className={'w-100'}>
                    <p>Name:</p>
                    <input
                        className={errors.name !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                        name={'name'}
                        type={'text'}
                        value={input.name}
                        onChange={handleInput}
                        placeholder={'Enter customer name'}
                    />
                    <p className={'login-error-msg'}><small>{errors.name !== undefined ? errors.name[0] : null}</small></p>
                </label>
                <label className={'w-100'}>
                    <p>Phone:</p>
                    <input
                        className={errors.phone !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                        name={'phone'}
                        type={'number'}
                        value={input.phone}
                        onChange={handleInput}
                        placeholder={'Enter customer phone number'}
                    />
                    <p className={'login-error-msg'}><small>{errors.phone !== undefined ? errors.phone[0] : null}</small></p>
                </label>

                <label className={'w-100'}>
                    <p>Email:</p>
                    <input
                        className={errors.email !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                        name={'email'}
                        type={'email'}
                        value={input.email}
                        onChange={handleInput}
                        placeholder={'Enter customer email'}
                    />
                    <p className={'login-error-msg'}><small>{errors.email !== undefined ? errors.email[0] : null}</small></p>
                </label>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleCustomerCreate} className='btn btn-theme' dangerouslySetInnerHTML={{__html: isLoading ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>\n' +
                        '  loading...' : 'Add Customer'}}/>
            </Modal.Footer>
        </Modal>
    );
};

export default AddCustomer;