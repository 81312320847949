import React from 'react'
import {Link} from "react-router-dom";
import GlobalFunction from "../../GlobalFunction";

const SideBar = () => {
    return (
        <div id="layoutSidenav_nav">
            <nav className="sb-sidenav accordion bg-theme-basic" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
                    <div className="nav">
                        <Link className="nav-link" to="/">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Dashboard
                        </Link>
                        <div className="sb-sidenav-menu-heading">Product</div>
                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#product" aria-expanded="false" aria-controls="collapseLayouts">
                            <div className="sb-nav-link-icon"><i className="fa-brands fa-product-hunt"></i></div>
                            Product
                            <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                        </a>
                        <div className="collapse" id="product" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                            <nav className="sb-sidenav-menu-nested nav">
                                <Link className="nav-link" to="/product">Product list</Link>
                                {GlobalFunction.isAdmin() &&
                                    <>
                                        <Link className="nav-link" to="/product/create">Add Product</Link>
                                        <Link className="nav-link" to="/product/trash">Trash Product</Link>
                                    </>

                                }

                            </nav>
                        </div>
                        {
                            GlobalFunction.isAdmin() &&
                            <>
                                <div className="sb-sidenav-menu-heading">Shops</div>
                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#shop" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div className="sb-nav-link-icon"><i className="fa-brands fa-product-hunt"></i></div>
                                    Shop
                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a>
                                <div className="collapse" id="shop" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <Link className="nav-link" to="/shop">Shop list</Link>
                                        <Link className="nav-link" to="/shop/create">Add Shop</Link>
                                    </nav>
                                </div>
                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#salesManager" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div className="sb-nav-link-icon"><i className="fa-brands fa-product-hunt"></i></div>
                                    Sales Manager
                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a>
                                <div className="collapse" id="salesManager" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <Link className="nav-link" to="/sales-manager">Sales Manager list</Link>
                                        <Link className="nav-link" to="/sales-manager/create">Add Sales Manager</Link>
                                    </nav>
                                </div>


                                <div className="sb-sidenav-menu-heading">Management</div>


                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div className="sb-nav-link-icon"><i className="fas fa-list"></i></div>
                                    Category
                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a>
                                <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <Link className="nav-link" to="/category">Category list</Link>
                                        <Link className="nav-link" to="/category/create">Add Category</Link>
                                    </nav>
                                </div>

                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#sub_category" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div className="sb-nav-link-icon"><i className="fas fa-list"></i></div>
                                    Sub Category
                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a>
                                <div className="collapse" id="sub_category" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <Link className="nav-link" to="/sub-category">Sub Category list</Link>
                                        <Link className="nav-link" to="/sub-category/create">Add Sub Category</Link>
                                    </nav>
                                </div>

                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#brand" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div className="sb-nav-link-icon"><i className="fa-brands fa-font-awesome-flag"></i></div>
                                    Brand
                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a>
                                <div className="collapse" id="brand" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <Link className="nav-link" to="/brand">Brand list</Link>
                                        <Link className="nav-link" to="/brand/create">Add Brand</Link>
                                    </nav>
                                </div>

                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#supplier" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div className="sb-nav-link-icon"><i className="fas fa-industry"></i></div>
                                    Supplier
                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a>
                                <div className="collapse" id="supplier" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <Link className="nav-link" to="/supplier">Supplier list</Link>
                                        <Link className="nav-link" to="/supplier/create">Add Supplier</Link>
                                    </nav>
                                </div>
                                <Link className="nav-link" to="product-attributes">
                                    <div className="sb-nav-link-icon"><i className="fas fa-chart-area"></i></div>
                                    Product Attribute
                                </Link>
                            </>
                        }
                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#order" aria-expanded="false" aria-controls="collapseLayouts">
                            <div className="sb-nav-link-icon"><i className="fa-brands fa-product-hunt"></i></div>
                            Order
                            <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                        </a>
                        <div className="collapse" id="order" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                            <nav className="sb-sidenav-menu-nested nav">
                                <Link className="nav-link" to="/order">Order list</Link>
                                <Link className="nav-link" to="/order/create">Add Order</Link>
                            </nav>
                        </div>

                        <div className="sb-sidenav-menu-heading">Accessories</div>
                        <Link className='nav-link' to={'generate-bar-code'}>
                            <div className='sb-nav-link-icon'><i className='fas fa-table'/></div>
                            Generate bar code
                        </Link>
                        <Link className='nav-link' to={'/report'}>
                            <div className='sb-nav-link-icon'><i className='fas fa-table'/></div>
                            Report
                        </Link>

                    </div>
                </div>
                <div className="sb-sidenav-footer bg-theme text-silver">
                    <div className="small">Logged in as:</div>
                    {localStorage.name !== undefined ? localStorage.name : null}
                </div>
            </nav>
        </div>
    )
};
export default SideBar;
