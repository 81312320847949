import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "../../partials/BreadCrumb";
import Loader from "../../partials/mini_component/Loader";
import {Link} from "react-router-dom";
import NoDataFound from "../../partials/mini_component/NoDataFound";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import axios from "axios";
import Constants from "../../../Constants";
import CardButton from "../../partials/mini_component/CardButton";
import GlobalFunction from "../../../GlobalFunction";

const ProductList = () => {


    const [input,setInput] = useState({
        order_by : 'id',
        per_page : 10,
        direction : 'asc',
        search : ''

    })


    const [isLoading, setIsLoading] = useState(false)
    const [products, setProducts] = useState([])

    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState([])
    const [startFrom, setStartFrom] = useState(1)
    const [activePage, setActivePage] = useState(1)
    const [productColumns, setProductColumns] = useState([])

    const getProductColumns = () => {
        axios.get(`${Constants.BASE_URL}/get-product-columns`).then(res => {
            setProductColumns(res.data)
        })
    }

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const handleProductDelete = (id) =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "Product will be deleted!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constants.BASE_URL}/product/${id}`).then(res=>{
                    getProducts()
                    Swal.fire({
                        position: 'top-end',
                        icon: res.data.cls,
                        title: res.data.msg,
                        showConfirmButton: false,
                        toast: true,
                        timer: 1500
                    })
                })
            }
        })

    }

    const getProducts = (pageNumber=1) => {
        setIsLoading(true)
        axios.get(`${Constants.BASE_URL}/product?page=${pageNumber}&search=${input.search}&order_by=${input.order_by}&per_page=${input.per_page}&direction=${input.direction}`).then(res =>{
            setProducts(res.data.data)
            setItemsCountPerPage(res.data.meta.per_page)
            setStartFrom(res.data.meta.from)
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getProducts()
        getProductColumns()
    }, [])
    return (
        <>
            <BreadCrumb title={'All Product'}/>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4">
                        <CardButton
                            card_btn
                            title={'Product List'}
                            heading={'h5'}
                            link={'/product/create'}
                            icon={'fa-add'}
                            btn_color={'theme'}
                            button_text={' Add'}
                            btn_size={'sm'}
                        />
                        <div className={'card-body'}>
                            <div className="search-area mb-4">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="w-100">
                                            <p>Search</p>
                                            <input
                                                className="form-control form-control-sm"
                                                name={'search'}
                                                type={'search'}
                                                value={input.search}
                                                onChange={handleInput}
                                                placeholder={'Search..'}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="w-100">
                                            <p>Order By</p>
                                            <select
                                                className="form-select form-select-sm"
                                                name={'order_by'}
                                                value={input.order_by}
                                                onChange={handleInput}
                                            >
                                                {productColumns.map((column, index) =>(
                                                    <option key={index} value={column.id}>{column.name}</option>
                                                ))}

                                            </select>
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="w-100">
                                            <p>Order Direction</p>
                                            <select
                                                className="form-select form-select-sm"
                                                name={'direction'}
                                                value={input.direction}
                                                onChange={handleInput}
                                            >
                                                <option value={'asc'}>ASC</option>
                                                <option value={'desc'}>DSC</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="w-100">
                                            <p>Per Page</p>
                                            <select
                                                className="form-select form-select-sm"
                                                name={'per_page'}
                                                value={input.per_page}
                                                onChange={handleInput}
                                            >
                                                <option value={10}>10</option>
                                                <option value={25}>25</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className={'col-md-2'}>
                                        <div className={'d-grid mt-4'}>
                                            <button onClick={()=>getProducts(1)} className='btn btn-sm btn-theme'><i className="fa-solid fa-magnifying-glass"/> Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isLoading ? <Loader/> :
                                <div className="table-responsive">
                                    <table className={'my-table table table-sm product-table table-hover table-striped table-bordered'}>
                                        <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Name</th>
                                            <th>SKU</th>
                                            <th>Category</th>
                                            <th>IN STOCK</th>
                                            <th>Price</th>
                                            <th>Created ON</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.keys(products).length > 0 ? products.map((product, index)=> (
                                            <tr key={index}>
                                                <td><img src={product.photo} alt={product.name} className={'img-thumbnail rounded-circle table-image'}/></td>
                                                <td>{product.name}</td>
                                                <td>{product.sku}</td>
                                                <td>{product.category}</td>
                                                <td>{product.stock}</td>


                                                <td>{product.price}</td>
                                                <td> {product.created_at}</td>
                                                <td>
                                                    <Link to={`/product/${product.id}`}><button className={'btn btn-sm btn-theme my-1'}><i className="fa-solid fa-eye"/></button></Link>
                                                    {GlobalFunction.isAdmin() ?
                                                    <>
                                                        <Link to={`/product/edit/${product.id}`}><button className={'btn btn-sm btn-primary my-1 mx-1'}><i className="fa-solid fa-edit"/></button></Link>
                                                        <button onClick={()=>handleProductDelete(product.id)} className={'btn btn-sm btn-danger my-1'}><i className="fa-solid fa-trash"/></button>
                                                    </> : null
                                                    }
                                                </td>
                                            </tr>
                                        )): <NoDataFound/> }

                                        </tbody>
                                    </table>
                                </div>
                            }

                        </div>
                        <div className="card-footer">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={5}
                                onChange={getProducts}
                                nextPageText={'Next'}
                                prevPageText={'Previous'}
                                itemClass={'page-item'}
                                linkClass={'page-link'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductList;