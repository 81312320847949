import React, {useState} from 'react'
import axios from 'axios';
import Constants from "../../../Constants";

export const Login = () => {
    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const handleInput = (e) => setInput(prevState => ({ ...prevState, [e.target.name]: e.target.value }))

    const handleLogin = () => {
        setIsLoading(true)
        axios.post(`${Constants.BASE_URL}/login`,input).then(res=>{
            localStorage.email  = res.data.email
            localStorage.name   = res.data.name
            localStorage.phone  = res.data.phone
            localStorage.photo  = res.data.photo
            localStorage.token  = res.data.token
            localStorage.role   = res.data.role
            localStorage.branch = JSON.stringify(res.data.branch)
            setIsLoading(false)
            window.location.reload()
        }).catch(errors => {
            setIsLoading(false)
            if (errors.response.status === 422){
                setErrors(errors.response.data.errors)
            }
        })
    }
    return (
        <>
            <div className='container-fluid bg-theme' id='login'>
                <div className='row'>
                    <div className='col-md-5 col-sm-5'>
                        <div className='card bg-theme'>
                            <div className='card-header'>
                                <h4>Login</h4>
                            </div>
                            <div className='card-body'>
                                <label className='w-100 mt-4'>
                                    <p>Email/Phone</p>
                                    <input
                                        className={errors.email !== undefined ? 'is-invalid form-control mt-2' : 'form-control mt-2'}
                                        type={'text'}
                                        name={'email'}
                                        value={input.email}
                                        onChange={handleInput}
                                        placeholder={'Enter your email/phone'}
                                    />
                                    <p className={'login-error-msg'}><small>{errors.email !== undefined ? errors.email[0] : null}</small></p>
                                </label>

                                <label className='w-100'>
                                    <p>Password</p>
                                    <input
                                        className={errors.password !== undefined ? 'is-invalid form-control mt-2' : 'form-control mt-2'}
                                        type={'password'}
                                        name={'password'}
                                        value={input.password}
                                        onChange={handleInput}
                                        placeholder={'Enter your password'}
                                    />
                                    <p className={'login-error-msg'}><small>{errors.password !== undefined ? errors.password[0] : null}</small></p>
                                </label>
                                <label className='w-100'>
                                    <p>Login As</p>
                                    <select
                                        className={errors.user_type !== undefined ? 'is-invalid form-select mt-2' : 'form-control mt-2'}
                                        name={'user_type'}
                                        value={input.user_type}
                                        onChange={handleInput}
                                    >
                                        <option>Select User Role</option>
                                        <option value={1}>Admin</option>
                                        <option value={2}>Sales Manager</option>
                                    </select>
                                    <p className={'login-error-msg'}><small>{errors.user_type !== undefined ? errors.user_type[0] : null}</small></p>
                                </label>
                                <div className='d-grid mt-4'>
                                    <button onClick={handleLogin} className='btn btn-outline-warning' dangerouslySetInnerHTML={{__html: isLoading ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>\n' +
                                            '  Logging...' : 'Login'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
