import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "../../partials/BreadCrumb";
import axios from "axios";
import Constants from "../../../Constants";
import NoDataFound from "../../partials/mini_component/NoDataFound";
import Loader from "../../partials/mini_component/Loader";
import AddCustomer from "../supplier/partials/AddCustomer";
import ShowOrderConfirmation from "../supplier/partials/ShowOrderConfirmation";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import CardButton from "../../partials/mini_component/CardButton";

const OrderCreate = () => {

    const [input,setInput] = useState({
        order_by : 'id',
        per_page : 10,
        direction : 'asc',
        search : ''

    })
    const navigate = useNavigate()
    const [customerInput,setCustomerInput] = useState('')
    const [customers,setCustomers] = useState([])

    const [modalShow, setModalShow] = React.useState(false);
    const [orderConfirmationModalShow, setOrderConfirmationModalShow] = React.useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [isLoading, setIsLoading] = useState(false)
    const [products, setProducts] = useState([])

    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState([])
    const [startFrom, setStartFrom] = useState(1)
    const [activePage, setActivePage] = useState(1)
    const [carts, setCarts] = useState({})
    const [orderSummary, setOrderSummary] = useState({
        items: 0,
        amount: 0,
        discount: 0,
        pay_able: 0,
        customer: '',
        customer_id: 0,
        paid_amount: 0,
        due_amount: 0,
        payment_method_id: 1,
        transaction_id: '',
    });

    const [order, setOrder] = useState({})

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const getPaymentMethods = () => {
        axios.get(`${Constants.BASE_URL}/get-payment-methods`).then(res => {
            setPaymentMethods(res.data)
        })
    }
    const handleOrderPlace = () => {
        setIsLoading(true)
        axios.post(`${Constants.BASE_URL}/order`, {carts: carts, 'order_summary' : orderSummary}).then(res=>{
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast: true,
                timer:1500
            })
            if(res.data.flag !== undefined){
                setOrderConfirmationModalShow(false)
                navigate(`/order/${res.data.order_id}`)
            }
            setIsLoading(false)
        })
    }
    const handleCustomerSearch = (e) => {
        setCustomerInput(e.target.value)

    }

    const handleIncrease = (id) =>{
        if (carts[id].stock > carts[id].quantity){
            setCarts(prevState => ({
                ...prevState,
                [id]:{
                    ...prevState[id], quantity: carts[id].quantity + 1
                }
            }))
        }

    }

    const handleDecrease = (id) =>{
        if (carts[id].quantity > 1){
            setCarts(prevState => ({
                ...prevState,
                [id]:{
                    ...prevState[id], quantity: carts[id].quantity - 1
                }
            }))
        }
    }

    const selectCustomer = (customer) =>{
        setOrder(prevState => ({...prevState, customer_id: customer.id}))
        setOrderSummary(prevState => ({...prevState, customer : customer.name + ' - ' + customer.phone}))
        setOrderSummary(prevState => ({...prevState, customer_id : customer.id}))
    }
    const getCustomer = () => {
        axios.get(`${Constants.BASE_URL}/customer?&search=${customerInput}`).then(res => {
            setCustomers(res.data)
            setIsLoading(false)
        })
    }
    const handleCart = (id) =>{
        products.map((product, index)=>{
            if (product.id === id){
                if (carts[id] === undefined){
                    setCarts(prevState => ({...prevState, [id] : product}))
                    setCarts(prevState => ({
                        ...prevState,
                        [id]:{
                            ...prevState[id], quantity: 1
                        }
                    }))
                }else {
                    if (carts[id].stock > carts[id].quantity){
                        setCarts(prevState => ({
                            ...prevState,
                            [id]:{
                                ...prevState[id], quantity: carts[id].quantity + 1
                            }
                        }))
                    }
                }

            }
        })
    }

    const removeCart = (key) => {
        setCarts(current => {
            const copy = {...current};
            delete copy[key];
            return copy;
        })
    }

    const getProducts = (pageNumber=1) => {
        setIsLoading(true)
        axios.get(`${Constants.BASE_URL}/product?page=${pageNumber}&search=${input.search}&order_by=${input.order_by}&per_page=${input.per_page}&direction=${input.direction}`).then(res =>{
            setProducts(res.data.data)
            setItemsCountPerPage(res.data.meta.per_page)
            setStartFrom(res.data.meta.from)
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setIsLoading(false)
        })
    }

    const calculateOrderSummary = () =>{
        let items = 0
        let amount = 0
        let discount = 0
        let pay_able = 0
        Object.keys(carts).map((key)=>{
            amount+=carts[key].original_price * carts[key].quantity
            discount+=carts[key].selling_price.discount * carts[key].quantity
            pay_able+=carts[key].selling_price.price * carts[key].quantity
            items+=carts[key].quantity
        })
        setOrderSummary(prevState => ({...prevState,
            items: items,
            amount: amount,
            discount: discount,
            pay_able: pay_able,
            paid_amount: pay_able
        }))
    }

    const handleOrderSummaryInput = (e) => {
        if (e.target.name == 'paid_amount' && orderSummary.pay_able >= e.target.value){
            setOrderSummary(prevState => ({...prevState,
                paid_amount: e.target.value,
                due_amount: orderSummary.pay_able - e.target.value,
            }))
        }else if(e.target.name == 'payment_method_id'){
            setOrderSummary(prevState => ({...prevState,
                payment_method_id: e.target.value,
            }))
            if(e.target.value == 1){
                setOrderSummary(prevState => ({...prevState,
                    transaction_id: ''
                }))
            }
        }else if(e.target.name == 'transaction_id'){
            setOrderSummary(prevState => ({...prevState,
                transaction_id: e.target.value,
            }))
        }

    }


    useEffect(() => {
        getProducts()
        getPaymentMethods()
    }, [])

    useEffect(() =>{
        calculateOrderSummary()
    },[carts])

    return (
        <>
            <BreadCrumb title={'Create Order'} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                            <CardButton
                                card_btn
                                title={'Create Order'}
                                heading={'h5'}
                                link={'/order'}
                                icon={'fa-list'}
                                btn_color={'theme'}
                                button_text={' See Order List'}
                                btn_size={'sm'}
                            />
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <CardButton title={'Product List'} heading={'h6'}/>
                                        <div className="card-body flex-grow-1 flex-shrink-1 p-2">
                                            <div className="product-search-area mb-4">
                                                <div className='input-group'>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        type={'search'}
                                                        name={'search'}
                                                        value={input.search}
                                                        onChange={handleInput}
                                                        placeholder={'Search...'}
                                                    />
                                                    <button onClick={getProducts} className='input-group-text btn-theme'><i className='fa-solid fa-search'/></button>
                                                </div>
                                            </div>
                                            {isLoading ? <Loader/> :
                                                <div>
                                            {Object.keys(products).length > 0 ? products.map((product, index)=>(
                                                <div className="d-flex flex-start py-2 border-bottom" key={index}>

                                                    <img className="thumbnail-img shadow-1-strong" src={product.photo} alt="avatar"
                                                         width="65" height="65"/>
                                                    <div className="flex-grow-1 flex-shrink-1 px-2">
                                                        <div>
                                                            <div className="d-flex justify-content-between">
                                                                <p className="mb-1 small">
                                                                    <strong>{product.name}</strong>
                                                                </p>
                                                            </div>
                                                            <div className="mb-1">
                                                                <p className="small">
                                                                    Original Price: <strong>{product.price}</strong>
                                                                </p>
                                                                <p className="small">
                                                                    Price: {product.selling_price.price} {product.selling_price.symbol} | Discount: {product.selling_price.discount}
                                                                </p>
                                                                <p className="small">
                                                                    SKU: {product.sku} | Stock: {product.stock}
                                                                </p>
                                                            </div>
                                                            <button className='btn btn-theme btn-sm mx-2'><i className="fa-solid fa-eye"/></button>
                                                            <button className='btn btn-success btn-sm' onClick={()=>handleCart(product.id)}><i className="fa-solid fa-plus"/></button>

                                                        </div>
                                                    </div>
                                                </div>

                                            )): <NoDataFound/> }
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <CardButton title={'Product List'}/>
                                        <div className="card-body">
                                            <div className="order-summary">
                                                <p className='pb-2'><small className='text-theme'><strong>Customer : </strong>{orderSummary.customer}</small></p>
                                                <table className="table table-hover table-bordered table-sm">
                                                    <tbody>
                                                    <tr>
                                                        <th>Total Items :</th>
                                                        <td className='small text-end'>{orderSummary.items}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Original Price :</th>
                                                        <td className='small text-end'>{orderSummary.amount}৳</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Discount :</th>
                                                        <td className='small text-end'>(-) {orderSummary.discount}৳</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Net Payable :</th>
                                                        <th className='small text-end text-theme'>{orderSummary.pay_able}৳</th>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {Object.keys(carts).map((key)=>(
                                                <div className="d-flex flex-start py-2 border-bottom" key={key}>

                                                    <img className="thumbnail-img shadow-1-strong" src={carts[key].photo} alt="avatar"
                                                         width="65" height="65"/>
                                                    <div className="flex-grow-1 flex-shrink-1 px-2">
                                                        <div>
                                                            <div className="d-flex justify-content-between">
                                                                <p className="mb-1 small">
                                                                    <strong>{carts[key].name}</strong>
                                                                </p>
                                                            </div>
                                                            <div className="mb-1">
                                                                <p className="small">
                                                                    Original Price: <strong>{carts[key].price}</strong>
                                                                </p>
                                                                <p className="small">
                                                                    Price: {carts[key].selling_price.price} {carts[key].selling_price.symbol} | {carts[key].selling_price.discount}
                                                                </p>
                                                                <p className="small">
                                                                    SKU: {carts[key].sku} | Stock: {carts[key].stock}
                                                                </p>
                                                                <p>Quantity:
                                                                    <button onClick={()=>handleDecrease(carts[key].id)} disabled={carts[key].quantity <= 1} className='quantity-btn'>-</button>
                                                                    <span>{carts[key].quantity}</span>
                                                                    <button onClick={()=>handleIncrease(carts[key].id)} disabled={carts[key].stock <= carts[key].quantity} className='quantity-btn'>+</button>
                                                                </p>
                                                            </div>
                                                            <button className='btn btn-sm btn-theme'><i className="fa-solid fa-eye"/></button>
                                                            <button className='btn btn-danger btn-sm mx-2' onClick={()=>removeCart(key)}><i className="fa-solid fa-times"/></button>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <CardButton
                                            card_btn
                                            title={'Customer List'}
                                            handle_function={() => setModalShow(true)}
                                            icon={'fa-add'}
                                            btn_color={'theme'}
                                            button_text={' Add'}
                                            btn_size={'sm'}
                                        />
                                        <div className="card-body">
                                            <div className='input-group'>
                                                <input
                                                    className="form-control form-control-sm"
                                                    type={'search'}
                                                    name={'search'}
                                                    value={customerInput}
                                                    onChange={handleCustomerSearch}
                                                    placeholder={'Search...'}
                                                />
                                                <button onClick={getCustomer} className='input-group-text btn-theme'><i className='fa-solid fa-search'/></button>
                                            </div>
                                            <ul className="customer-list">
                                                {customers.map((customer, index)=>(
                                                    <li className={orderSummary.customer_id === customer.id ? 'text-theme order-product-container px-2' : 'order-product-container px-2'}
                                                        onClick={()=>selectCustomer(customer)} key={index}>{customer.name}</li>
                                                ))}

                                            </ul>
                                            <div className="d-grid mt-4">
                                                <button disabled={orderSummary.items === 0 || orderSummary.customer_id === 0}
                                                        onClick={() => setOrderConfirmationModalShow(true)} className="btn-theme btn">Place Order</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddCustomer
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
            />

            <ShowOrderConfirmation
                show={orderConfirmationModalShow}
                onHide={() => setOrderConfirmationModalShow(false)}
                order_summary = {orderSummary}
                carts = {carts}
                is_loading = {isLoading}
                handleOrderPlace = {handleOrderPlace}
                handleOrderSummaryInput = {handleOrderSummaryInput}
                paymentMethods = {paymentMethods}
            />
        </>
    );
};

export default OrderCreate;