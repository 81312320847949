import React from 'react';
import {BreadCrumb} from "./components/partials/BreadCrumb";

const Error = () => {
    return (
        <>
            <BreadCrumb title={'404 Not Found'}/>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h1 className="text-lg-center fw-bold">404</h1>
                    <h3 className="text-success text-center">Oops! You seem to be lost.</h3>
                </div>
            </div>
        </>
    );
};

export default Error;