import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { AuthLayout } from '../layout/AuthLayout';
import { Login } from '../modules/auth/Login';
import Error from "../../Error";

const PublicRouter = createBrowserRouter([
    {
        path: '/',
        element: <AuthLayout/>,
        children: [
            {
                path: '/',
                element: <Login/>
            },
            {
                path: '*',
                element: <Error/>
            }
        ]
    }
])
export default PublicRouter;