import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "../../partials/BreadCrumb";
import {Button, Card, Collapse, Table} from "react-bootstrap";
import CardButton from "../../partials/mini_component/CardButton";
import {useParams} from "react-router-dom";
import Constants from "../../../Constants";
import axios from "axios";
import GlobalFunction from "../../../GlobalFunction";

const OrderDetails = () => {
    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [order, setOrder] = useState()

    const getOrderDetails = () => {
        setIsLoading(true)
        axios.get(`${Constants.BASE_URL}/order/${params.id}`).then(res => {
            setOrder(res.data.data)
            setIsLoading(false)
        })
    }
    useEffect(() => {
        getOrderDetails()
    },[])
    
    return (
        <>
            <BreadCrumb title={'Order Details'}/>
            <Card>
                <CardButton
                    card_btn
                    title={`${order?.order_number} Order Details`}
                    heading={'h5'}
                    link={'/order'}
                    icon={'fa-list'}
                    btn_color={'success'}
                    button_text={' List'}
                    btn_size={'sm'}
                />
                <Card.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <Card>
                                <CardButton title='Customer Details'/>
                                <Card.Body>
                                    <Table striped bordered hover size="sm">
                                        <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <td>{order?.customer?.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone</th>
                                            <td>{order?.customer?.phone}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{order?.customer?.email}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6">
                            <Card>
                                <CardButton title='Shop Details'/>
                                <Card.Body>
                                    <Table striped bordered hover size="sm">
                                        <tbody>
                                        <tr>
                                            <th className='align-middle'>Shop Name</th>
                                            <td className='align-middle'>
                                                <img src={order?.shop?.logo} alt={'shop logo'} className='table-image img-thumbnail me-2'/>
                                                {order?.shop?.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className='align-middle'>Sales Manager</th>
                                            <td className='align-middle'>{order?.sales_manager?.name}</td>
                                        </tr>
                                        <tr>
                                            <th className='align-middle'>Email</th>
                                            <td className='align-middle'>{order?.shop?.email}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-12 mt-4'>
                            <div className='card h-100'>
                                <div className='card-header'>
                                    <h5>Order Summary</h5>
                                </div>
                                <div className="card-body">
                                    <table className='table table-hover table-bordered table-striped'>
                                        <tbody>
                                            <tr>
                                                <th className='align-middle'>Order Number</th>
                                                <td className='align-middle'><strong>{order?.order_number}</strong></td>
                                                <th className='align-middle'>Total Items</th>
                                                <td className='align-middle'>{order?.quantity}</td>
                                            </tr>

                                            <tr>
                                                <th className='align-middle'>Order Status</th>
                                                <td className='align-middle'><strong>{order?.order_status_string}</strong></td>
                                                <th className='align-middle'>Payment Status</th>
                                                <td className='align-middle'>{order?.payment_status}</td>
                                            </tr>
                                            <tr>
                                                <th className='align-middle'>Payment Method</th>
                                                <td className='align-middle'><strong>{order?.payment_method?.name}</strong></td>
                                                <th className='align-middle'>Account Number</th>
                                                <td className='align-middle'>{order?.payment_method?.account_number}</td>
                                            </tr>
                                            <tr>
                                                <th className='align-middle'>Sub Total</th>
                                                <td className='align-middle'><strong>{GlobalFunction.formatPrice(order?.sub_total)}</strong></td>
                                                <th className='align-middle'>Discount</th>
                                                <td className='align-middle'>{GlobalFunction.formatPrice(order?.discount)}</td>
                                            </tr>
                                            <tr>
                                                <th className='align-middle'>Total</th>
                                                <td className='align-middle'><strong>{GlobalFunction.formatPrice(order?.total)}</strong></td>
                                            </tr>
                                            <tr>
                                                <th className='align-middle'>Paid Amount</th>
                                                <td className='align-middle'><strong>{GlobalFunction.formatPrice(order?.paid_amount)}</strong></td>
                                                <th className='align-middle'>Due Amount</th>
                                                <td className='align-middle'><strong className='text-danger'>{GlobalFunction.formatPrice(order?.due_amount)}</strong></td>
                                            </tr>
                                            <tr>
                                                <th className='align-middle'>Order Placed</th>
                                                <td className='align-middle'><strong>{order?.created_at}</strong></td>
                                                <th className='align-middle'>Payment Updated</th>
                                                <td className='align-middle'>{order?.updated_at}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-4">
                            <div className='card h-100'>
                                <div className='card-header'>
                                    <h5>Order Items Details</h5>
                                </div>
                                <div className='card-body'>
                                    <table className='table table-hover table-bordered table-striped'>
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                                <th>Info</th>
                                                <th>Quantity</th>
                                                <th>Photo</th>
                                                <th>Amounts</th>
                                                <th>Sub Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {order?.order_details.map((product, index)=> (
                                            <tr key={index}>
                                                <td className='align-middle'>{++index}</td>
                                                <td className='align-middle'>
                                                    <p>Product Name: {product.name}</p>
                                                    <p>SKU: {product.sku}</p>
                                                    <p>Supplier: {product.supplier}</p>
                                                </td>
                                                <td className='align-middle'>
                                                    <p>Brand: {product.brand}</p>
                                                    <p>Category: {product.category}</p>
                                                    <p>Sub Category: {product.sub_category}</p>
                                                </td>
                                                <td className='align-middle'>{product.quantity}</td>
                                                <td className='align-middle'>
                                                    <img src={product.photo} alt={'Product Photo'} className='table-image img-thumbnail'/>
                                                </td>
                                                <td className='align-middle'>
                                                    <p>Original Price: {product.price}</p>
                                                    <p>Discount: {GlobalFunction.formatPrice(product?.selling_price?.discount)}</p>
                                                    <p>Selling Price: {GlobalFunction.formatPrice(product?.selling_price?.price)}</p>
                                                </td>
                                                <td className='align-middle text-end'>
                                                    {GlobalFunction.formatPrice(product?.selling_price?.price * product.quantity)}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-4 mb-5">
                            <div className='card h-100'>
                                <div className='card-header'>
                                    <h5>Transaction</h5>
                                </div>
                                <div className='card-body'>
                                    <table className='table table-hover table-bordered table-striped'>
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Trx ID</th>
                                                <th>Amount</th>
                                                <th>Customer</th>
                                                <th>Payment Method</th>
                                                <th>Status</th>
                                                <th>Transaction By</th>
                                                <th>Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {order?.transactions.map((transaction, index)=> (
                                            <tr key={index}>
                                                <td className='align-middle'>{++index}</td>
                                                <td className='align-middle'>{transaction.transaction_id}</td>
                                                <td className='align-middle'>{GlobalFunction.formatPrice(transaction.amount)}</td>
                                                <td className='align-middle'>
                                                    <p>Name: {transaction.customer_name}</p>
                                                    <p>Phone: {transaction.customer_phone}</p>
                                                </td>
                                                <td className='align-middle'>
                                                    <p>{transaction.payment_method_name}</p>
                                                    <p>{transaction.account_number}</p>
                                                </td>
                                                <td className='align-middle'>
                                                    <p>{transaction.status}</p>
                                                    <p>{transaction.transaction_type}</p>
                                                </td>
                                                <td className='align-middle'>
                                                    {transaction.transaction_by}
                                                </td>
                                                <td className='align-middle'>{transaction.created_at}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default OrderDetails;