import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Constants from "../../../Constants";
import Swal from "sweetalert2";
import {BreadCrumb} from "../../partials/BreadCrumb";
import CardHeader from "../../partials/mini_component/CardHeader";
import Select from "react-select";
import {isAccordionItemSelected} from "react-bootstrap/AccordionContext";

const AddProduct = () => {
    const navigate = useNavigate()
    const [input, setInput] = useState({status: 1})
    const [attribute_input, setAttribute_input] = useState({})
    const [specification_input, setSpecification_input] = useState({})
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [brands, setBrands] = useState([])
    const [countries, setCountries] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [attributeFiled, setAttributeField] = useState([])
    const [attributeFieldId, setAttributeFieldId] = useState(1)
    const [attributes, setAttributes] = useState([])
    const [specificationFiled, setSpecificationFiled] = useState([])
    const [specificationFiledId, setSpecificationFiledId] = useState(1)
    const [allSubcategories, setAllSubcategories] = useState([])
    const [shops, setShops] = useState([])

    const handleSpecificationFieldRemove = (id) => {
        setSpecificationFiled(oldValues => {
            return oldValues.filter(specificationFiled => specificationFiled !== id)
        })
        setSpecification_input(current => {
            const copy = {...current};
            delete copy[id];
            return copy;
        })
        setSpecificationFiledId(specificationFiledId-1)
    }
    const handleSpecificationFields = (id) => {
        setSpecificationFiledId(specificationFiledId+1)
        setSpecificationFiled(prevState => [...prevState, specificationFiledId])
    }
    const handleAttributeFieldsRemove = (id) => {
        setAttributeField(oldValues => {
            return oldValues.filter(attributeFiled => attributeFiled !== id)
        })
        setAttribute_input(current => {
            const copy = {...current};
            delete copy[id];
            return copy;
        })
        setAttributeFieldId(attributeFieldId-1)
    }
    const handleAttributeFields = (id) => {
        if (attributes.length >= attributeFieldId){
            setAttributeFieldId(attributeFieldId+1)
            setAttributeField(prevState => [...prevState, attributeFieldId])
        }
    }
    const handleSpecificationInput = (e, id) => {
        setSpecification_input(prevState => ({
            ...prevState,
            [id]:{
                ...prevState[id], [e.target.name]: e.target.value
            }
        }))
    }
    const handleAttributeInput = (e, id) => {
        setAttribute_input(prevState => ({
            ...prevState,
            [id]:{
                ...prevState[id], [e.target.name]: e.target.value
            }
        }))
    }


    const getAddProductData = () => {
        axios.get(`${Constants.BASE_URL}/get-add-product-data`).then(res=>{
            setCategories(res.data.categories)
            setBrands(res.data.brands)
            setCountries(res.data.countries)
            setSuppliers(res.data.suppliers)
            setAttributes(res.data.attributes)
            setAllSubcategories(res.data.sub_categories)
            setShops(res.data.shops)
        })
    }

    const handleInput = (e) =>{
        if (e.target.name === 'name'){
            setSubCategories([])
            let slug = e.target.value
            slug = slug.toLowerCase()
            slug = slug.replaceAll(' ', '-')
            setInput(prevState => ({ ...prevState, slug: slug}))
        }else  if(e.target.name === 'category_id'){
            setSubCategories([])
            let category_id = parseInt(e.target.value)
            if (!Number.isNaN(category_id)){
                let sub_category = allSubcategories.filter((item, index)=> {
                    return item.category_id === category_id
                })
                setSubCategories(sub_category)
            }


        }
        setInput(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
    }
    const handleProductCreate = () => {
        setIsLoading(true)
        axios.post(`${Constants.BASE_URL}/product`, input).then(res=>{
            setIsLoading(false)
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast:true,
                timer: 1500
            })
            if (res.data.product_id !== 'undefined'){
                navigate('/product/photo/'+res.data.product_id)
            }

        }).catch(errors => {
            setIsLoading(false)
            if (errors.response.status === 422){
                setErrors(errors.response.data.errors)
            }
        })

    }

    const handleMultipleSelect = (e) => {
        let value = []
        for ( const item of e) {
            value.push(item.value)
        }
        setInput(prevState => ({...prevState, shop_id: value}))
    }


    useEffect( ()=>{

        getAddProductData()
    },[])

    useEffect(()=>{
        setInput(prevState => ({...prevState, attributes: attribute_input}))
    }, [attribute_input])

    useEffect(()=>{
        setInput(prevState => ({...prevState, specifications: specification_input}))
    }, [specification_input])

    return (
        <>
            <BreadCrumb title={'Add Product'} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <CardHeader
                                title={'Add Product'}
                                link={'/product'}
                                icon={'fa-list'}
                                button_text={'List'}
                            />
                        </div>
                        <div className="card-body">
                            {/*    name, slug, description, serial, photo, status */}
                            <div className="row">
                                <div className="col-md-12">
                                    <label className={'w-100'}>
                                        <p>Select Shop:</p>
                                        <Select
                                            isMulti
                                            options={shops}
                                            onChange={handleMultipleSelect}
                                            name={'shop_id[]'}
                                            placeholder={'Select shop'}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />

                                        <p className={'login-error-msg'}><small>{errors.shop_id !== undefined ? errors.shop_id[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <label className={'w-100'}>
                                        <p>Name:</p>
                                        <input
                                            className={errors.name !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            name={'name'}
                                            type={'text'}
                                            value={input.name}
                                            onChange={handleInput}
                                            placeholder={'Enter product name'}
                                        />
                                        <p className={'login-error-msg'}><small>{errors.name !== undefined ? errors.name[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <label className={'w-100'}>
                                        <p>Slug:</p>
                                        <input
                                            className={errors.slug !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            name={'slug'}
                                            type={'text'}
                                            value={input.slug}
                                            onChange={handleInput}
                                            placeholder={'Enter product slug'}
                                        />
                                        <p className={'login-error-msg'}><small>{errors.slug !== undefined ? errors.slug[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Select Category:</p>
                                        <select
                                            className={errors.category_id !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            name={'category_id'}
                                            value={input.category_id}
                                            onChange={handleInput}
                                        >
                                            <option value={''}>Select Category</option>
                                            {categories.map((category, index)=>(
                                                <option key={index} value={category.id}>{category.name}</option>
                                            ))}
                                        </select>
                                        <p className={'login-error-msg'}><small>{errors.category_id !== undefined ? errors.category_id[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Select Sub Category:</p>
                                        <select
                                            className={errors.sub_category_id !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            name={'sub_category_id'}
                                            value={input.sub_category_id}
                                            onChange={handleInput}
                                            disabled={Object.keys(subCategories).length < 1}
                                        >
                                            <option value={''}>Select Sub Category</option>
                                            {subCategories.map((sub_category, index)=>(
                                                <option key={index} value={sub_category.id}>{sub_category.name}</option>
                                            ))}
                                        </select>
                                        <p className={'login-error-msg'}><small>{errors.sub_category_id !== undefined ? errors.sub_category_id[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Select Brand:</p>
                                        <select
                                            className={errors.brand_id !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            name={'brand_id'}
                                            value={input.brand_id}
                                            onChange={handleInput}
                                            disabled={Object.keys(brands).length < 1}
                                        >
                                            <option value={''}>Select Brand</option>
                                            {brands.map((brand, index)=>(
                                                <option key={index} value={brand.id}>{brand.name}</option>
                                            ))}
                                        </select>
                                        <p className={'login-error-msg'}><small>{errors.brand_id !== undefined ? errors.brand_id[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Select Country:</p>
                                        <select
                                            className={errors.country_id !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            name={'country_id'}
                                            value={input.country_id}
                                            onChange={handleInput}
                                            disabled={Object.keys(countries).length < 1}
                                        >
                                            <option value={''}>Select Country</option>
                                            {countries.map((country, index)=>(
                                                <option key={index} value={country.id}>{country.name}</option>
                                            ))}
                                        </select>
                                        <p className={'login-error-msg'}><small>{errors.country_id !== undefined ? errors.country_id[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Select Product Supplier:</p>
                                        <select
                                            className={errors.supplier_id !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            name={'supplier_id'}
                                            value={input.supplier_id}
                                            onChange={handleInput}
                                            placeholder={'Select product supplier'}
                                        >
                                            <option>Select Supplier</option>
                                            {suppliers.map((supplier, index)=>(
                                                <option key={index} value={supplier.id}>{supplier.name}</option>
                                            ))}
                                        </select>
                                        <p className={'login-error-msg'}><small>{errors.supplier_id !== undefined ? errors.supplier_id[0] : null}</small></p>
                                    </label>
                                </div>

                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Status:</p>
                                        <select
                                            className={errors.status !== undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
                                            name={'status'}
                                            value={input.status}
                                            onChange={handleInput}
                                        >
                                            <option value={1}>Active</option>
                                            <option value={0}>In Active</option>
                                        </select>
                                        <p className={'login-error-msg'}><small>{errors.status !== undefined ? errors.status[0] : null}</small></p>
                                    </label>

                                </div>
                                <div className="col-md-12">
                                    <div className="card my-4">
                                        <div className="card-header">
                                            <h5>Select Product Attribute</h5>
                                        </div>
                                        <div className="card-body">
                                            {attributeFiled.map((id, ind)=>(
                                                <div key={ind} className="row my-2 align-items-baseline">
                                                    <div className="col-md-5">
                                                        <label className={'w-100 mt-4'}>
                                                            <p>Select Attribute</p>
                                                            <select
                                                                className='form-select mt-2'
                                                                name={'attribute_id'}
                                                                value={attribute_input[id] !== undefined ? attribute_input[id].attribute_id : null}
                                                                onChange={(e)=>handleAttributeInput(e, id)}
                                                                placeholder={'Select product attribute'}
                                                            >
                                                                <option>Select Attribute</option>
                                                                {attributes.map((value, index)=>(
                                                                    <option value={value.id}>{value.name}</option>
                                                                ))}
                                                            </select>
                                                            <p className={'login-error-msg'}>
                                                                <small>{errors.attribute_id !== undefined ? errors.attribute_id[0] : null}</small></p>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <label className={'w-100 mt-4'}>
                                                            <p>Select Attribute Value</p>
                                                            <select
                                                                className={'form-select mt-2'}
                                                                name={'value_id'}
                                                                value={attribute_input[id] !== undefined ? attribute_input[id].value_id : null}
                                                                onChange={(e)=>handleAttributeInput(e, id)}
                                                                placeholder={'Select product attribute value'}
                                                            >
                                                                <option>Select Attribute Value</option>
                                                                {attributes.map((value, index)=>(
                                                                    <>
                                                                        {attribute_input[id] !== undefined && value.id == attribute_input[id].attribute_id ? value.value.map((atr_value, value_ind)=>(
                                                                            <option value={atr_value.id}>{atr_value.name}</option>
                                                                        )):null}
                                                                    </>
                                                                ))}
                                                            </select>
                                                            <p className={'login-error-msg'}>
                                                                <small>{errors.attribute_id !== undefined ? errors.attribute_id[0] : null}</small></p>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        {attributeFiled.length -1 === ind ?
                                                            <button className={'btn btn-danger'} onClick={()=>handleAttributeFieldsRemove(id)}>
                                                                <i className="fa-solid fa-minus"/>
                                                            </button>:null
                                                        }
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <button className={'btn btn-success'} onClick={handleAttributeFields}>
                                                        <i className="fa-solid fa-plus"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="card my-4">
                                        <div className="card-header">
                                            <h5>Product Specifications</h5>
                                        </div>
                                        <div className="card-body">
                                            {specificationFiled.map((id, ind)=>(
                                                <div key={ind} className="row my-2 align-items-baseline">
                                                    <div className="col-md-5">
                                                        <label className={'w-100 mt-4'}>
                                                            <p>Specification Name</p>
                                                            <input
                                                                className={'form-control mt-2'}
                                                                type={'text'}
                                                                name={'name'}
                                                                value={specification_input[id] != undefined ? specification_input[id].name : null}
                                                                onChange={(e)=>handleSpecificationInput(e, id)}
                                                                placeholder={'Enter Product Specification Name'}
                                                            />
                                                            <p className={'login-error-msg'}>
                                                                <small>{errors.name != undefined ? errors.name[0] : null}</small></p>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <label className={'w-100 mt-4'}>
                                                            <p>Specification Value</p>
                                                            <input
                                                                className='form-control mt-2'
                                                                type={'text'}
                                                                name={'value'}
                                                                value={specification_input[id] != undefined ? specification_input[id].value : null}
                                                                onChange={(e)=>handleSpecificationInput(e, id)}
                                                                placeholder={'Enter Product Specification Name'}
                                                            />
                                                            <p className={'login-error-msg'}>
                                                                <small>{errors.name != undefined ? errors.name[0] : null}</small></p>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        {specificationFiled.length -1 == ind ?
                                                            <button className={'btn btn-danger'} onClick={()=>handleSpecificationFieldRemove(id)}>
                                                                <i className="fa-solid fa-minus"/>
                                                            </button> : null
                                                        }

                                                    </div>
                                                </div>
                                            ))}

                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <button className={'btn btn-success'} onClick={handleSpecificationFields}>
                                                        <i className="fa-solid fa-plus"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 my-4">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Product Price and Stock</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className={'w-100 mt-4'}>
                                                        <p>Product Cost</p>
                                                        <input
                                                            className={errors.cost != undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                            type={'number'}
                                                            name={'cost'}
                                                            value={input.cost}
                                                            onChange={handleInput}
                                                            placeholder={'Enter Product Cost'}
                                                        />
                                                        <p className={'login-error-msg'}>
                                                            <small>{errors.cost != undefined ? errors.cost[0] : null}</small></p>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className={'w-100 mt-4'}>
                                                        <p>Product Sale Price</p>
                                                        <input
                                                            className={errors.price !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                            type={'number'}
                                                            name={'price'}
                                                            value={input.price}
                                                            onChange={handleInput}
                                                            placeholder={'Enter Product Price'}
                                                        />
                                                        <p className={'login-error-msg'}>
                                                            <small>{errors.price !== undefined ? errors.price[0] : null}</small></p>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className={'w-100 mt-4'}>
                                                        <p>Discount %</p>
                                                        <input
                                                            className={errors.discount_percent !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                            type={'number'}
                                                            name={'discount_percent'}
                                                            value={input.discount_percent}
                                                            onChange={handleInput}
                                                            placeholder={'Enter Product Discount (%)'}
                                                        />
                                                        <p className={'login-error-msg'}>
                                                            <small>{errors.discount_percent !== undefined ? errors.discount_percent[0] : null}</small></p>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className={'w-100 mt-4'}>
                                                        <p>Discount Fixed Amount</p>
                                                        <input
                                                            className={errors.discount_fixed !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                            type={'number'}
                                                            name={'discount_fixed'}
                                                            value={input.discount_fixed}
                                                            onChange={handleInput}
                                                            placeholder={'Enter Product Discount Fixed'}
                                                        />
                                                        <p className={'login-error-msg'}>
                                                            <small>{errors.discount_fixed !== undefined ? errors.discount_fixed[0] : null}</small></p>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className={'w-100 mt-4'}>
                                                        <p>Discount Start Date</p>
                                                        <input
                                                            className={errors.discount_start !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                            type={'datetime-local'}
                                                            name={'discount_start'}
                                                            value={input.discount_start}
                                                            onChange={handleInput}
                                                            placeholder={'Enter Discount Start Date'}
                                                        />
                                                        <p className={'login-error-msg'}>
                                                            <small>{errors.discount_start !== undefined ? errors.discount_start[0] : null}</small></p>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className={'w-100 mt-4'}>
                                                        <p>Discount End Date</p>
                                                        <input
                                                            className={errors.discount_end !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                            type={'datetime-local'}
                                                            name={'discount_end'}
                                                            value={input.discount_end}
                                                            onChange={handleInput}
                                                            placeholder={'Enter Discount End Date'}
                                                        />
                                                        <p className={'login-error-msg'}>
                                                            <small>{errors.discount_end !== undefined ? errors.discount_end[0] : null}</small></p>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className={'w-100 mt-4'}>
                                                        <p>Prouct Stock</p>
                                                        <input
                                                            className={errors.stock !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                            type={'number'}
                                                            name={'stock'}
                                                            value={input.stock}
                                                            onChange={handleInput}
                                                            placeholder={'Enter Product Stock'}
                                                        />
                                                        <p className={'login-error-msg'}>
                                                            <small>{errors.stock !== undefined ? errors.stock[0] : null}</small></p>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className={'w-100 mt-4'}>
                                                        <p>Prouct SKU</p>
                                                        <input
                                                            className={errors.sku !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                                            type={'text'}
                                                            name={'sku'}
                                                            value={input.sku}
                                                            onChange={handleInput}
                                                            placeholder={'Enter Product SKU'}
                                                        />
                                                        <p className={'login-error-msg'}>
                                                            <small>{errors.sku !== undefined ? errors.sku[0] : null}</small></p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label className={'w-100 mt-4'}>
                                        <p>Description</p>
                                        <textarea
                                            className={errors.description !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            name={'description'}
                                            value={input.description}
                                            onChange={handleInput}
                                            placeholder={'Enter product description'}
                                        />
                                        <p className={'login-error-msg'}>
                                            <small>{errors.description !== undefined ? errors.description[0] : null}</small>
                                        </p>
                                    </label>
                                </div>

                                <div className={'col-md-12'}>
                                    <div className={'row justify-content-center'}>
                                        <div className={'col-md-4'}>
                                            <div className={'d-grid mt-4'}>
                                                <button onClick={handleProductCreate} className='btn btn-theme' dangerouslySetInnerHTML={{__html: isLoading ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>\n' +
                                                        '  Loading...' : 'Next'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
/*

1. title
2. slug
3. description
4. category_id
5. sub_category_id
6. brand_id
7. status
8. cost
9. price
10. discount_percent
11. discount_amount
12. discount_start
13. discount_end
14. stock
15. country_id
16. sku
17. created_by_id
18. updated_by_id
18. provider_id

---------------product_attributes--------------
1. product_id
2. attribute_id
3. attribute_value_id


---------------product_specifications----------
1. product_id
2. name
3. value

---------------product_photos------------------
1. product_id
2. photo
3. is_primary

---------------product_seo---------------------
1. product_id
2. meta_title
3. meta_description
4. meta_keywords
5. og_image
*/

export default AddProduct;