const GlobalFunction = {
    logOut(){
        localStorage.removeItem('email')
        localStorage.removeItem('name')
        localStorage.removeItem('photo')
        localStorage.removeItem('phone')
        localStorage.removeItem('token')
        localStorage.removeItem('role')
        window.location.href = window.location.origin
    },
    isAdmin(){ return localStorage.role != undefined && localStorage.role == 1;},


    formatPrice(price,symbol = '৳')
    {
        return Intl.NumberFormat('us').format(price)+ symbol
    }
}
export default GlobalFunction;