import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "../partials/BreadCrumb";
import CardButton from "../partials/mini_component/CardButton";
import axios from "axios";
import Constants from "../../Constants";

const Report = () => {
    const [report, setReport] = useState([])

    const getReports = () => {
        axios.get(`${Constants.BASE_URL}/get-reports`).then(res => {
            setReport(res.data)
        })
    }

    useEffect(()=>{
        getReports()
    },[])
    return (
        <>
            <BreadCrumb title={'Report'} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <CardButton title={'Report'} heading={'h5'}/>
                        <div className="card-body">
                            <div className='card'>
                                <CardButton title={'Sales(Branch)'} heading={'h6'}/>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-cart-shopping fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Sales</h6>
                                                            <h4>{report.total_sale}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-cart-plus fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Purchase</h6>
                                                            <h4>{report.total_purchase}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-3">*/}
                                        {/*    <div className='card report-card'>*/}
                                        {/*        <div className='card-body'>*/}
                                        {/*            <div className='d-flex align-items-center'>*/}
                                        {/*                <div className='flex-shrink-0'>*/}
                                        {/*                    <i className='fa-solid fa-rotate-left fa-2x'/>*/}
                                        {/*                </div>*/}
                                        {/*                <div className='flex-grow-1 ms-3'>*/}
                                        {/*                    <h6>Total Sales Return</h6>*/}
                                        {/*                    <h4>0</h4>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-3">*/}
                                        {/*    <div className='card report-card'>*/}
                                        {/*        <div className='card-body'>*/}
                                        {/*            <div className='d-flex align-items-center'>*/}
                                        {/*                <div className='flex-shrink-0'>*/}
                                        {/*                    <i className='fa-solid fa-rotate-left fa-flip-horizontal fa-2x'/>*/}
                                        {/*                </div>*/}
                                        {/*                <div className='flex-grow-1 ms-3'>*/}
                                        {/*                    <h6>Purchase Return</h6>*/}
                                        {/*                    <h4>0</h4>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className="fa-solid fa-hand-holding-dollar fa-2x"></i>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Today's Sale</h6>
                                                            <h4>{report.total_sale_today}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-person-walking-arrow-loop-left fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Today Purchase</h6>
                                                            <h4>{report.total_purchase_today}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-3 mt-4">*/}
                                        {/*    <div className='card report-card'>*/}
                                        {/*        <div className='card-body'>*/}
                                        {/*            <div className='d-flex align-items-center'>*/}
                                        {/*                <div className='flex-shrink-0'>*/}
                                        {/*                    <i className='fa-solid fa-right-left fa-2x'/>*/}
                                        {/*                </div>*/}
                                        {/*                <div className='flex-grow-1 ms-3'>*/}
                                        {/*                    <h6>Sales Return</h6>*/}
                                        {/*                    <h4>0</h4>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-3 mt-4">*/}
                                        {/*    <div className='card report-card'>*/}
                                        {/*        <div className='card-body'>*/}
                                        {/*            <div className='d-flex align-items-center'>*/}
                                        {/*                <div className='flex-shrink-0'>*/}
                                        {/*                    <i className='fa-solid fa-right-left fa-2x'/>*/}
                                        {/*                </div>*/}
                                        {/*                <div className='flex-grow-1 ms-3'>*/}
                                        {/*                    <h6>Purchase Return</h6>*/}
                                        {/*                    <h4>0</h4>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className='card mt-4'>
                                <CardButton title={'Stock'} heading={'h6'}/>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-box-open fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Product</h6>
                                                            <h4>{report.total_product}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-box fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Stock</h6>
                                                            <h4>{report.total_stock}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-battery-quarter fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Low Stock</h6>
                                                            <h4>{report.low_stock}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-dollar-sign fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Stock Value (buy)</h6>
                                                            <h4>{report.buy_value}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-dollar-sign fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Stock Value (sale)</h6>
                                                            <h4>{report.sale_value}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-dollar-sign fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Possible Profit</h6>
                                                            <h4>{report.possible_profit}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mt-4'>
                                <CardButton title={'Expense(Branch)'} heading={'h6'}/>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-hand-holding-dollar fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Expense</h6>
                                                            <h4>12,258</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-sack-dollar fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Today Expense</h6>
                                                            <h4>12,258</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mt-4'>
                                <CardButton title={'Withdrawals(Branch)'} heading={'h6'}/>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-cart-shopping fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Sales</h6>
                                                            <h4>12,258</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-cart-plus fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Purchase</h6>
                                                            <h4>12,258</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mt-4'>
                                <CardButton title={'Profit'} heading={'h6'}/>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-cart-shopping fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Sales</h6>
                                                            <h4>12,258</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className='card report-card'>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <i className='fa-solid fa-cart-plus fa-2x'/>
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <h6>Total Purchase</h6>
                                                            <h4>12,258</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Report;