import React from 'react';

const NoDataFound = () => {
    return (
        <div className={'no-data-found-container'}>
            <div className={'no-data-found mt-3 w-100 text-center'}>
                <p className={'text-danger'}>No data found!</p>
            </div>
        </div>

    );
};

export default NoDataFound;