import React, {useEffect, useState} from 'react'
import $ from 'jquery'
import Swal from "sweetalert2";
import axios from "axios";
import Constants from "../../Constants";
import GlobalFunction from '../../GlobalFunction'
import {Link} from "react-router-dom";


const Nav = () => {

    const [branch, setBranch] = useState({})
    const handleLogout = () =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You will be logged out!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes logged out!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${Constants.BASE_URL}/logout`).then(res=>{
                    GlobalFunction.logOut()
                }).catch(errors => {
                    GlobalFunction.logOut()
                })
            }
        })
    }

    useEffect(() => {
        if(localStorage.branch != undefined){
            setBranch(JSON.parse(localStorage.branch))
        }
    }, [])
    const handleSidebar = () => {
        $('body').toggleClass('sb-sidenav-toggled')
    }
    return (
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-theme">
            <Link className="navbar-brand ps-3" to="/">Admin Panel</Link>
            <button onClick={handleSidebar} className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle"><i className="fas fa-bars"></i></button>
            
            <ul className="navbar-nav align-items-center ms-auto me-3 me-lg-4">
                <p className='text-white'>
                    <strong>{branch != undefined ? branch.name + ' | ' : ''} </strong>
                    {localStorage.name != undefined ? localStorage.name:null}
                </p>
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle align-items-center" id="navbarDropdown"
                          to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img className='rounded-circle' width={'20px'}
                             src={branch.logo != undefined ? branch.logo : localStorage.photo}
                             alt={localStorage.name != undefined ? localStorage.name : null}
                        />
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="#">Settings</a></li>
                        <li><a className="dropdown-item" href="#">Activity Log</a></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><button onClick={handleLogout} className="dropdown-item">Logout</button></li>
                    </ul>
                </li>
            </ul>
        </nav>
    )
};

export default Nav;