import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import  Master  from '../layout/Master'
import { Dashboard } from '../modules/Dashboard'
import Error500 from "../modules/Error500";
import AddCategory from "../modules/category/AddCategory";
import CategoryList from "../modules/category/CategoryList";
import CategoryEdit from "../modules/category/CategoryEdit";
import SubCategoryAdd from "../modules/subCategory/SubCategoryAdd";
import SubCategoryList from "../modules/subCategory/SubCategoryList";
import SubCategoryEdit from "../modules/subCategory/SubCategoryEdit";
import AddBrand from "../modules/brand/AddBrand";
import BrandList from "../modules/brand/BrandList";
import BrandEdit from "../modules/brand/BrandEdit";
import SupplierAdd from "../modules/supplier/SupplierAdd";
import SupplierList from "../modules/supplier/SupplierList";
import SupplierEdit from "../modules/supplier/SupplierEdit";
import ProductAttributes from "../modules/productAttribute/productAttributes";
import AddProduct from "../modules/products/AddProduct";
import ProductList from "../modules/products/ProductList";
import TrashProduct from "../modules/products/TrashProduct";
import AddProductPhoto from "../modules/products/AddProductPhoto";
import Error from "../../Error";
import ShopAdd from "../modules/shop/ShopAdd";
import ShopList from "../modules/shop/ShopList";
import ShopEdit from "../modules/shop/ShopEdit";
import AddSalesManager from "../modules/salesManager/AddSalesManager";
import SalesManagerList from "../modules/salesManager/SalesManagerList";
import OrderList from "../modules/order/OrderList";
import OrderCreate from "../modules/order/OrderCreate";
import OrderDetails from "../modules/order/OrderDetails";
import BarCode from "../modules/bar_code/BarCode";
import Report from "../report/Report";
import ProductDetails from "../modules/products/ProductDetails";
import ProductEdit from "../modules/products/ProductEdit";

const ProjectRouter = createBrowserRouter([
    {
        path: '/',
        element: <Master/>,
        children: [
            {
                path: '/',
                element: <Report/>
            },
            {
                path: '/category/create',
                element: <AddCategory/>
            },
            {
                path: '/category/edit/:id',
                element: <CategoryEdit/>
            },
            {
                path: '/category',
                element: <CategoryList/>
            },
            {
                path: '/sub-category/create',
                element: <SubCategoryAdd/>
            },
            {
                path: '/sub-category/edit/:id',
                element: <SubCategoryEdit/>
            },
            {
                path: '/sub-category',
                element: <SubCategoryList/>
            },
            {
                path: '/brand',
                element: <BrandList/>
            },
            {
                path: '/brand/create',
                element: <AddBrand/>
            },
            {
                path: '/brand/edit/:id',
                element: <BrandEdit/>
            },
            {
                path: '/supplier',
                element: <SupplierList/>
            },
            {
                path: '/supplier/create',
                element: <SupplierAdd/>
            },
            {
                path: '/supplier/edit/:id',
                element: <SupplierEdit/>
            },
            {
                path: '/product-attributes',
                element: <ProductAttributes/>
            },
            {
                path: '/product',
                element: <ProductList/>
            },
            {
                path: '/product/create',
                element: <AddProduct/>
            },
            {
                path: '/product/:id',
                element: <ProductDetails/>
            },
            {
                path: '/product/edit/:id',
                element: <ProductEdit/>
            },
            {
                path: '/product/photo/:id',
                element: <AddProductPhoto/>
            },
            {
                path: '/product/trash',
                element: <TrashProduct/>
            },
            {
                path: '/shop',
                element: <ShopList/>
            },
            {
                path: '/shop/create',
                element: <ShopAdd/>
            },
            {
                path: '/shop/edit/:id',
                element: <ShopEdit/>
            },
            {
                path: '/sales-manager',
                element: <SalesManagerList/>
            },
            {
                path: '/sales-manager/create',
                element: <AddSalesManager/>
            },
            {
                path: '/order',
                element: <OrderList/>
            },
            {
                path: '/order/create',
                element: <OrderCreate/>
            },
            {
                path: '/order/:id',
                element: <OrderDetails/>
            },
            {
                path: '/generate-bar-code',
                element: <BarCode/>
            },
            {
                path: '/report',
                element: <Report/>
            },
            {
                path: '/error-500',
                element: <Error500/>
            },
            {
                path: '*',
                element: <Error/>
            }
        ]
    }
])
export default ProjectRouter;